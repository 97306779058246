import ky from 'ky';
import Downloader from './lib/js-file-downloader';
import { includes } from 'lodash';
import { notify } from './utils/helper';
import { authDefault } from './reducer';
import authenticatedApi from './services/authenticatedApi';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { LAMBDA_FORMS, LAMBDA_ORG, LAMBDA_FILE, LAMBDA_SEARCH } from './services/constants';

const ITEMS_PER_PAGE = 10;

export function getLocalAuth() {
  let auth: Auth = authDefault;
  try {
    const storedAuth = localStorage.getItem('paper-auth');
    if (storedAuth) {
      auth = JSON.parse(storedAuth);
    }
  } catch (error) {
    console.warn('Failed to parse auth object from localStorage', error);
  }
  return auth;
}

export function handleUnauthorizedError(error: any) {
  if (
    error &&
    (error.message === 'Unauthorized' ||
      error.message === 'Failed to get an access token' ||
      error.message === 'Forbidden' ||
      error.message === '403' ||
      error.message === '401' ||
      error.message === 'Access token is missing or invalid, waiting for reauthentication...')
  ) {
    console.warn('Access token is expired or invalid. Reauthenticating...');
    const event = new CustomEvent('OnApiAuthError', {
      detail: {
        pathname: toRelativeUrl(window.location.href, window.location.origin),
      },
    });
    window.dispatchEvent(event);

    return true;
  }
  if (error && includes(error.message, 'Token is invalid or authentication is in progress')) {
    return true;
  }
  return false;
}

export type SearchResults = {
  error?: string;
  count: number;
  hits: Array<any>;
  query: string;
};

/** API Requests */

export async function filePresign(name: string, eventId: string): Promise<any> {
  try {
    let data = (await authenticatedApi.post<any>(
      `${LAMBDA_FILE}/files/presign?eventId=${eventId}`,
      {
        body: JSON.stringify({ fileName: name }),
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )) as any;
    return data.url;
  } catch (error) {
    console.error(error);
    if (!handleUnauthorizedError(error)) {
      notify('Failed to fetch files', 'error');
    }
    throw error;
  }
}

export async function fileValidate(name: string): Promise<boolean> {
  try {
    let data = (await authenticatedApi.get<any>(
      `${LAMBDA_FILE}/files/validate/${encodeURIComponent(name)}`
    )) as any;
    return data.exists;
  } catch (error) {
    console.error(error);
    if (!handleUnauthorizedError(error)) {
      notify(`Failed to validate file`, 'error');
    }
    throw error;
  }
}

export async function fileSave(url: string, fileType: string, binaryFile: any): Promise<any> {
  try {
    let data = (await ky.put(url, {
      body: binaryFile,
      headers: {
        'Content-Type': fileType,
      },
    })) as any;
    return data;
  } catch (error) {
    console.error(error);
    if (!handleUnauthorizedError(error)) {
      notify('Failed to fetch!!!', 'error');
    }
    throw error;
  }
}

interface FilesGetI {
  id?: string;
  fileNames?: string;
}

export async function filesGet(props: FilesGetI): Promise<any> {
  const urlParts = [`${LAMBDA_FILE}/files`];
  if (props.id) {
    urlParts.push(`requestId=${props.id}`);
  }
  if (props.fileNames) {
    urlParts.push(`fileNames=${encodeURIComponent(props.fileNames)}`);
  }
  try {
    let response = (await authenticatedApi.get<any>(urlParts.join('?'),
      {
        headers: {
          'Content-Type': 'application/json',
        },
        timeout: 25000,
      }
    )) as any;

    return response.data;
  } catch (error) {
    console.error(error);
    if (!handleUnauthorizedError(error)) {
      notify('Failed to fetch!!!', 'error');
    }
    throw error;
  }
}

export async function fileDelete(name: string): Promise<any> {
  try {
    let data = (await authenticatedApi.delete<any>(
      `${LAMBDA_FILE}/files?fileName=${encodeURIComponent(name)}`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )) as any;
    return data;
  } catch (error) {
    console.error(error);
    if (!handleUnauthorizedError(error)) {
      notify(`Failed to delete the ${name} file`, 'error');
    }
    throw error;
  }
}

export async function fileDownload(name: string): Promise<any> {
  try {
    let presignResponse = (await authenticatedApi.get<any>(
      `${LAMBDA_FILE}/files/${encodeURIComponent(name)}`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )) as any;
    let response: Response | null = null;
    let blob: Blob | undefined = undefined;
    response = (await ky.get(presignResponse.url)) as any;
    blob = await response?.blob();
    if (blob) {
      const fileUrl = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = fileUrl;
      link.download = `${name}`;
      link.title = `${name}`;
      link.click();
      link.remove();
    }
  } catch (error) {
    console.error(error);
    if (!handleUnauthorizedError(error)) {
      notify(`Failed to download the ${name} file`, 'error');
    }
    throw error;
  }
}

export async function orgGet(type: string): Promise<any> {
  const test = `${LAMBDA_ORG}org/${encodeURIComponent(type)}`;
  try {
    let data = (await authenticatedApi.get<any>(test, {
      headers: {
        'Content-Type': 'application/json',
      },
    })) as any;
    return data.items;
  } catch (error) {
    console.error(error);
    if (!handleUnauthorizedError(error)) {
      notify('Failed to fetch!!!', 'error');
    }
    throw error;
  }
}

export async function saveOrg(orgData: any): Promise<any> {
  try {
    const res = (await authenticatedApi.post(`${LAMBDA_ORG}/org`, {
      body: JSON.stringify(orgData),
      headers: {
        'Content-Type': 'application/json',
      },
    })) as any;
    return res.data;
  } catch (error) {
    console.error(error);
    if (!handleUnauthorizedError(error)) {
      notify('Failed to save org data. Please try again later.', 'error');
    }
    return null;
  }
}

export async function fetchSearchResults(
  query: string,
  filters?: TransformedSearchFilters,
  offset: number = 0,
  narrow?: string,
  hitsCount: number = ITEMS_PER_PAGE
): Promise<SearchResults> {
  const urlParts = [
    `${LAMBDA_SEARCH}/v2/documents/search?q=${query}&items=${hitsCount}&offset=${offset}`,
  ];
  if (narrow) {
    urlParts.push(`narrow=${narrow}`);
  }
  try {
    const data = await authenticatedApi.post<SearchResults>(urlParts.join('&'), {
      body: JSON.stringify(filters),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return data;
  } catch (error) {
    console.error(error);

    if (!handleUnauthorizedError(error)) {
      notify(
        'Failed to fetch search results. Please try again later or with a different search request.',
        'error'
      );
    }

    throw error;
  }
}

export async function fetchMetadata(filters: TransformedSearchFilters): Promise<SearchResults> {
  try {
    const data = await authenticatedApi.post<SearchResults>(`${LAMBDA_SEARCH}/v2/metadata-export`, {
      body: JSON.stringify(filters),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return data;
  } catch (error) {
    console.error(error);
    if (!handleUnauthorizedError(error)) {
      notify(
        'Failed to fetch report. Please try again later or with a different search request.',
        'error'
      );
    }
    throw error;
  }
}

export async function publishForm(id: string): Promise<any> {
  try {
    const data = await authenticatedApi.post<any>(`${LAMBDA_FORMS}/forms/${id}/events`, {
      body: JSON.stringify({
        eventType: 'PublishForm',
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return data;
  } catch (error) {
    console.error(error);
    if (!handleUnauthorizedError(error)) {
      notify('Failed to publish form.', 'error');
    }
    throw error;
  }
}

export async function deleteForm(id: string): Promise<any> {
  try {
    const data = await authenticatedApi.post<any>(`${LAMBDA_FORMS}/forms/${id}/events`, {
      body: JSON.stringify({
        eventType: 'DeleteForm',
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return data;
  } catch (error) {
    console.error(error);
    if (!handleUnauthorizedError(error)) {
      notify('Failed to delete form.', 'error');
    }
    throw error;
  }
}

export async function getDocumentMetadata(id: string): Promise<any> {
  try {
    const data = await authenticatedApi.get(`${LAMBDA_FORMS}/docs/${id}`);
    return data;
  } catch (error) {
    console.error(error);
    if (!handleUnauthorizedError(error)) {
      notify('Failed to fetch document. Please try again later.', 'error');
    }
    return {}; // FIXME
  }
}

export async function fetchDocument(id: string) {
  try {
    const res = (await authenticatedApi.get(
      `${LAMBDA_SEARCH}/v2/documents/view?id=${id}`,
      undefined,
      false
    )) as Response;

    const fileUrl = await res.text();

    return fileUrl;
  } catch (error) {
    console.error(error);
    if (!handleUnauthorizedError(error)) {
      notify('Failed to fetch document. Please try again later.', 'error');
    }
    return null;
  }
}

export async function fetchDocEditDocument(id: string): Promise<DocEditDocumentResponse | null> {
  try {
    const res = (await authenticatedApi.get(
      `${LAMBDA_FORMS}/forms/${id}`,
      undefined,
      false
    )) as Response;
    const json = await res.json();

    return json;
  } catch (error: any) {
    if (error.message === '404' || error.message === 'Not Found')
      return { message: '404', success: true };
    if (!handleUnauthorizedError(error)) {
      notify('Failed to fetch document. Please try again later.', 'error');
    }
    return null;
  }
}

export async function fetchForms(): Promise<any> {
  try {
    const res = (await authenticatedApi.get(`${LAMBDA_FORMS}/forms`, undefined, false)) as Response;
    const json = await res.json();
    return json;
  } catch (error) {
    console.error(error);
    if (!handleUnauthorizedError(error)) {
      notify('Failed to fetch document. Please try again later.', 'error');
    }
    return null;
  }
}

export async function fetchRules(id: string): Promise<any> {
  try {
    const res = (await authenticatedApi.get(
      `${LAMBDA_FORMS}/forms/${id}/rules`,
      undefined,
      false
    )) as Response;
    const json = await res.json();
    return json;
  } catch (error) {
    console.error(error);
    if (!handleUnauthorizedError(error)) {
      notify('Failed to fetch products. Please try again later.', 'error');
    }
    return null;
  }
}

export async function saveRules(rule: any, id: string): Promise<any> {
  try {
    const res = (await authenticatedApi.put(`${LAMBDA_FORMS}/forms/${id}/rules`, {
      timeout: false,
      body: JSON.stringify(rule),
      headers: {
        'Content-Type': 'application/json',
      },
    })) as any;
    return res.data;
  } catch (error) {
    console.error(error);
    if (!handleUnauthorizedError(error)) {
      notify('Failed to save rule. Please try again later.', 'error');
    }
    return null;
  }
}

export async function deleteRule(id: string, ruleIndex: number): Promise<any> {
  try {
    const res = (await authenticatedApi.delete(`${LAMBDA_FORMS}/forms/${id}/rules/${ruleIndex}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })) as any;
    return res;
  } catch (error) {
    console.error(error);
    if (!handleUnauthorizedError(error)) {
      notify('Failed to delete rule. Please try again later.', 'error');
    }
    return null;
  }
}

export async function fetchRanges(id: string, index: number): Promise<any> {
  try {
    const res = (await authenticatedApi.get(
      `${LAMBDA_FORMS}/forms/${id}/rules/${index}`,
      undefined,
      false
    )) as any;
    return res.json();
  } catch (error) {
    console.error(error);
    if (!handleUnauthorizedError(error)) {
      notify('Failed to fetch states. Please try again later.', 'error');
    }
    return null;
  }
}
export async function saveRanges(id: string, index: number, rangesArray: any): Promise<any> {
  try {
    const res = (await authenticatedApi.put(`${LAMBDA_FORMS}/forms/${id}/rules/${index}`, {
      body: JSON.stringify(rangesArray),
      headers: {
        'Content-Type': 'application/json',
      },
    })) as any;
    return res;
  } catch (error) {
    console.error(error);
    if (!handleUnauthorizedError(error)) {
      notify('Failed to save states. Please try again later.', 'error');
    }
    return null;
  }
}

export async function saveDocEditMetaData(metadata: any, link: Link): Promise<null | Response> {
  try {
    const res = (await authenticatedApi.post(
      `${LAMBDA_FORMS}/forms${link && `?source=${link.source}&id=${link.id}`}`,
      {
        body: JSON.stringify(metadata),
      },
      false
    )) as Response;

    return res;
  } catch (error) {
    console.error(error);
    if (!handleUnauthorizedError(error)) {
      notify('Failed to fetch document. Please try again later.', 'error');
    }
    return null;
  }
}
export async function getFormLink(source: string, id: string): Promise<any> {
  try {
    return await authenticatedApi.get(`${LAMBDA_FORMS}/link?source=${source}&id=${id}`, {
      hooks: {
        afterResponse: [
          (_request: any, _options: any, response: any) => {
            if (response.status === 404) {
              return new Response(JSON.stringify({ message: 'Not Found' }));
            }
            return response;
          },
        ],
      },
    });
  } catch (error: any) {
    console.error(error);
    if (!handleUnauthorizedError(error)) {
      notify('Failed to link form. Please try again later.', 'error');
    }
    return null;
  }
}

export async function fetchAttachments(id: string): Promise<any> {
  try {
    return (await authenticatedApi.get(
      `${LAMBDA_FORMS}/forms/${id}/attachments`,
      undefined,
      true
    )) as Response;
  } catch (error) {
    console.error(error);
    if (!handleUnauthorizedError(error)) {
      notify('Failed to fetch attachments. Please try again later.', 'error');
    }
    return null;
  }
}

export async function saveAttachments(id: string, data: any): Promise<any> {
  try {
    const res = (await authenticatedApi.put(`${LAMBDA_FORMS}/forms/${id}/attachments`, {
      body: JSON.stringify(data),
    })) as Response;
    return res;
  } catch (error) {
    console.error(error);
    if (!handleUnauthorizedError(error)) {
      notify('Failed to save attachments. Please try again later.', 'error');
    }
    return null;
  }
}

export async function deleteAttachment(id: string, attachmentId: string, data: any): Promise<any> {
  try {
    const res = await authenticatedApi.delete(
      `${LAMBDA_FORMS}/forms/${id}/attachments/${attachmentId}`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      }
    );
    return res;
  } catch (error) {
    console.error(error);
    if (!handleUnauthorizedError(error)) {
      notify('Failed to delete attachments. Please try again later.', 'error');
    }
    return null;
  }
}

export async function getMergedDocumentsFile(formNumbers: string): Promise<File> {
  const auth = getLocalAuth();

  return (
    new Downloader({
      // NOTE: this one doesn't use KY!
      url: `${LAMBDA_SEARCH}/document-api/merged-documents/file?forms=${formNumbers}`,
      headers: [{ name: 'Authorization', value: auth.accessToken }],
      withCredentials: false, // to avoid CORS errors - Authorization header will be sent and used
      isResponseBase64Encoded: true,
    }) as unknown as Promise<any>
  )
    .then(() => {
      return new File([], 'fixme');
    })
    .catch((error: Error) => {
      console.error(error);
      if (!handleUnauthorizedError(error)) {
        notify('Failed to fetch documents. Please try again later.', 'error');
      }
      return new File([], 'fixme');
    });
}

export async function getDocumentsMeta(formNumbers: string): Promise<any> {
  try {
    const data = await authenticatedApi.get(`${LAMBDA_SEARCH}/documents?forms=${formNumbers}`);
    return data;
  } catch (error) {
    console.error(error);
    if (!handleUnauthorizedError(error)) {
      notify('Failed to fetch documents. Please try again later.', 'error');
    }
    return [];
  }
}

export async function getMergedDocumentsZip(formNumbers: string): Promise<any> {
  const auth = getLocalAuth();
  return (
    new Downloader({
      url: `${LAMBDA_SEARCH}/document-api/merged-documents/zip?forms=${formNumbers}`,
      headers: [{ name: 'Authorization', value: auth.accessToken }],
      withCredentials: false, // to avoid CORS errors - Authorization header will be sent and used
      isResponseBase64Encoded: true,
    }) as unknown as Promise<any>
  )
    .then(() => {
      return true;
    })
    .catch((error: Error) => {
      console.error(error);
      if (!handleUnauthorizedError(error)) {
        notify('Failed to fetch documents. Please try again later.', 'error');
      }
      return false;
    });
}

export async function getMergedDocumentsForPreview(formNumbers: string): Promise<File> {
  try {
    const res = (await authenticatedApi.get(
      `${LAMBDA_SEARCH}/document-api/merged-documents/file?forms=${formNumbers}`,
      undefined,
      false
    )) as Response;
    const blob = await base64ResponseToBlob(res);
    return blobToFile(blob, 'MergedDocuments.pdf');
  } catch (error) {
    if (!handleUnauthorizedError(error)) {
      notify('Failed to fetch documents. Please try again later.', 'error');
    }
    throw error;
  }
}

export async function getValidation(query: Array<ValidationQuery>): Promise<any> {
  try {
    const queryStr = encodeURIComponent(JSON.stringify(query));
    return authenticatedApi.get(`${LAMBDA_SEARCH}/document-api/validation/query?q=${queryStr}`);
  } catch (error) {
    if (!handleUnauthorizedError(error)) {
      notify('Failed to fetch documents. Please try again later.', 'error');
    }
    throw error;
  }
}

export async function logDocumentAccess(document: string, accessType: 'download' | 'view') {
  try {
    await authenticatedApi.post(`${LAMBDA_SEARCH}/log/access`, {
      body: JSON.stringify({ document, accessType }),
      headers: {
        'Content-Type': 'application/json',
      },
    });
  } catch (error) {
    console.error(error);
  }
}

/**
 * Converts an API Response containing base64-encoded binary to a Blob object
 * NOTE: API Gateway is unable to send raw binary data so it's coming as a Base64 string
 * Without API Gateway we could just call `await res.blob()`
 * @param res API Response object
 */
async function base64ResponseToBlob(res: Response) {
  const b64Data = await res.text();
  return await (await fetch(`data:application/pdf;base64,${b64Data}`)).blob();
}

function blobToFile(blob: Blob, id: string): File {
  var b: any = blob;
  b.lastModifiedDate = new Date();
  b.name = id;
  return b as File;
}
