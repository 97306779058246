import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import React, { useEffect, useRef, useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import Button from '../../styledComponents/Button';
import {
  filePresign,
  fileSave,
  filesGet,
  fileDelete,
  saveAttachments,
  fetchAttachments,
  deleteAttachment,
  fileDownload,
  fileValidate,
} from '../../../api';
import { v4 as uuidv4 } from 'uuid';
import { notify } from '../../../utils/helper';

enum FileStatus {
  UNSAVED = 'UNSAVED',
  SAVED = 'SAVED',
  PRESIGN = 'PRESIGN',
  CONVERSION = 'CONVERSION',
  CORRUPTED = 'CORRUPTED',
}

interface FileUploadProps {
  id?: string;
  disabled?: boolean;
}
export enum AttachmentCategory {
  PRIMARY = 'primary',
  NONPRIMARY = 'non-primary',
}

type Attachment = {
  fileName: string;
  status: FileStatus;
};

type AttachmentDetailed = {
  category?: string;
  contentType: string;
  requestId: string;
  timestamp: string;
} & Attachment;

type AttachmentVariants = Attachment | AttachmentDetailed;

const getAttachmentsDetails = async function* (attachments: any) {
  const ids = new Set<string>();

  // since filesGet returns all files for a given attachment id
  // therefore reducing the number of requests
  const getAttachmentFiles = ((ids: Set<string>) =>
    (attachmentId: string) => {
      const id = attachmentId.substring(0, attachmentId.indexOf('#')).trim(); // drop file extension
      if (!ids.has(id)) {
        const data = filesGet({ id })
        ids.add(id);
        return data;
      }
    })(ids);

  while (attachments.length > 0) {
    const attachment = attachments.shift();
    const fileType = attachment.fileName.slice(
      1 + attachment.fileName.lastIndexOf('.'),
      attachment.fileName.length
    );

    // having attachment.id means the attachment was saved using (new) file API
    if (attachment.id) {
      try {
        const data = await getAttachmentFiles(attachment.id)

        // enhance information with file data
        if (data && data.length) {
          yield Object.values(
            data.map((file: any) =>
              file.fileName === attachment.fileName
                ? Object.assign(file, {
                    category: attachment.category,
                  })
                : file
            )
          ) as AttachmentDetailed[];
        } else {
          yield [
            Object.assign(attachment, {
              contentType: fileType,
              status: FileStatus.SAVED,
            }),
          ] as AttachmentDetailed[];
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      yield [
        {
          fileName: attachment.fileName,
          contentType: fileType,
          status: FileStatus.SAVED,
          category: attachment.category,
        },
      ] as AttachmentDetailed[];
    }
  }
};

const saveAttachmentsDetails = async function* (id: string, attachments: any) {
  while (attachments.length > 0) {
    const attachment = attachments.shift();
    const { contentType, fileName, requestId } = attachment;
    try {
      await saveAttachments(id, { contentType, fileName, requestId });
      yield attachment;
    } catch (error) {
      console.log(error);
    }
  }
};

function FileUpload({ id, disabled }: FileUploadProps) {
  const [files, setFiles] = useState<File[]>([]);
  const [attachments, setAttachments] = useState<AttachmentVariants[]>([]);
  const [attachmentsUnsaved, setAttachmentsUnsaved] = useState<AttachmentDetailed[]>([]);
  const [statusChecker, setStatusChecker] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const inputRef = useRef<HTMLInputElement>(null);
  let interval: any;

  useEffect(() => {
    const saveFiles = async (files: File[]) => {
      const filePromises = files.map((file: File) => {
        const attachmentId = uuidv4();
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = async () => {
            try {
              const url = await filePresign(file.name, attachmentId);
              await fileSave(url, file.type, reader.result);
              const data = await filesGet({ fileNames: file.name });
              if (data.length > 0) {
                resolve(data[0]);
              }
            } catch (err) {
              reject(err);
            }
          };
          reader.onerror = (error) => {
            reject(error);
          };
          reader.readAsArrayBuffer(file);
        });
      });

      const filesReturned: any = await Promise.all(filePromises);
      setAttachmentsUnsaved(filesReturned);

      return files;
    };

    if (id && files.length) {
      saveFiles(files);
    }
  }, [files, id, setAttachmentsUnsaved]);

  useEffect(() => {
    const updateAttachmentDetailed = async (id: string) => {
      let newAttachments = [...attachments];

      for await (const attachment of saveAttachmentsDetails(id, attachmentsUnsaved)) {
        const index = attachments.findIndex(
          (att: AttachmentVariants) => att.fileName === attachment.fileName
        );
        if (index > -1) newAttachments[index] = attachment;
      }
      setAttachments(newAttachments);
      setAttachmentsUnsaved([]);
      setFiles([]);
      setStatusChecker(true);
    };

    if (id && attachmentsUnsaved.length > 0) {
      updateAttachmentDetailed(id);
    }
  }, [id, attachments, attachmentsUnsaved]);

  useEffect(() => {
    const getAttachments = async (id: string) => {
      try {
        const resp = await fetchAttachments(id);
        if (resp && resp.data) {
          let attachmentsState: AttachmentVariants[] = [...attachments];
          for await (const attachments of getAttachmentsDetails(resp.data)) {
            while (attachments.length > 0) {
              const attachment = attachments.shift();
              if (attachment) {
                const index = attachmentsState.findIndex((att: AttachmentVariants) => {
                  return att.fileName === attachment.fileName;
                });
                if (index < 0) {
                  attachmentsState.unshift(attachment);
                } else {
                  const at = attachmentsState.at(index) || {};
                  attachmentsState.splice(index, 1, Object.assign(at, { ...attachment }));
                }
              }
            }
          }
          setAttachments(attachmentsState);
        }
      } catch (error) {
        console.log(error);
      }
      setIsLoading(false);
    };

    if (id && isLoading) {
      getAttachments(id);
    } else {
      setIsLoading(false);
    }
  }, [id, isLoading, attachments]);

  const checkStatus = async () => {
    if (!statusChecker) return;
    interval = setInterval(async () => {
      const filesSaved = await filesGet({
        fileNames: attachments.map((attachment) => attachment.fileName).join(','),
      });
      const updatedAttachments = [...attachments];
      filesSaved.forEach((file: any) => {
        if (file.fileName) {
          let idx = updatedAttachments.findIndex(
            (attachment: any) => attachment && attachment.fileName === file.fileName
          );
          const attachment = idx > -1 && updatedAttachments.at(idx);
          if (attachment) {
            Object.assign(attachment, {
              status: file.status,
            });
            updatedAttachments.splice(idx, 1, attachment);
          } else {
            setAttachmentsUnsaved([file]);
            updatedAttachments.push(file);
          }
        }
      });
      setAttachments(updatedAttachments);

      if (
        updatedAttachments.every(
          (attachment: any) =>
            attachment.status === FileStatus[FileStatus.SAVED] ||
            attachment.status === FileStatus[FileStatus.CORRUPTED]
        )
      ) {
        setStatusChecker((prevState) => !prevState);
      }
    }, 3000);
  };

  useEffect(() => {
    checkStatus();

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
    //TODO: look into useCallback for checkStatus function
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusChecker]);

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const newFiles: FileList = event.target.files;
      const updatedAttachments: AttachmentVariants[] = [...attachments];
      const promises = Object.values(newFiles).map(async (file: File) => {
        const idx = updatedAttachments.findIndex(
          (attachment: AttachmentVariants) => attachment.fileName === file.name
        );
        const attachment = idx > -1 && updatedAttachments.at(idx);

        if (attachment) {
          Object.assign(attachment, {
            status: FileStatus.UNSAVED,
          });
          updatedAttachments.splice(idx, 1, attachment);
          return file;
        } else {
          const formName = file.name.slice(0, file.name.lastIndexOf('.'));
          const exists = await fileValidate(formName + '.');
          if (exists) {
            notify(`${formName} already exists. Please use a different name`, 'error');
            return;
          } else {
            updatedAttachments.push({
              fileName: file.name,
              status: FileStatus.UNSAVED,
            });
            return file;
          }
        }
      });

      const newFilesNoDuplicates: (File | undefined)[] = await Promise.all(promises);
      const newFilesCleaned: File[] = newFilesNoDuplicates.filter(
        (file): file is File => file !== undefined
      );
      setFiles([...files, ...newFilesCleaned]);
      if (updatedAttachments.length > 0) {
        setAttachments(updatedAttachments);
      }
    }
  };

  const handleFileRemove = async (id: string, attachmentId: string, name: string) => {
    const index = attachments.findIndex((attachment: any) => attachment.fileName === name);

    try {
      await fileDelete(name);
      await deleteAttachment(id, attachmentId, attachments.at(index));

      let newAttachments = Object.create(attachments);
      newAttachments.splice(index, 1);
      setAttachments(newAttachments);
      setAttachmentsUnsaved([]);
    } catch (err) {
      console.log(err);
    }
  };

  const handleFileDownload = async (name: string) => {
    try {
      await fileDownload(name);
    } catch (err) {
      console.log(err);
    }
  };

  const handlePrimaryAttachmentToogle = async (id: string, name: string, e: any) => {
    const { checked } = e.target;

    const updateAttachment = async (updatedAttachment: AttachmentDetailed) => {
      try {
        await saveAttachments(id, updatedAttachment);
      } catch (error) {
        console.log(error);
      }
    };

    const updateAttachmentPayload = async (index: number, category: string) => {
      const attachment = attachments.at(index) as unknown as AttachmentDetailed;
      await updateAttachment(
        Object.assign(attachment, {
          category,
        })
      );
      const updatedAttachments = Object.create(attachments);
      updatedAttachments.splice(index, 1, attachment);
      setAttachments(updatedAttachments);
    };

    let index = attachments.findIndex(
      (attachment: any) => attachment.category === AttachmentCategory.PRIMARY
    );
    let currentIndex = attachments.findIndex((attachment: any) => attachment.fileName === name);

    if (!checked) {
      updateAttachmentPayload(currentIndex, AttachmentCategory.NONPRIMARY);
    } else if (index > -1 && index !== currentIndex) {
      updateAttachmentPayload(currentIndex, AttachmentCategory.PRIMARY);
      updateAttachmentPayload(index, AttachmentCategory.NONPRIMARY);
    } else {
      updateAttachmentPayload(currentIndex, AttachmentCategory.PRIMARY);
    }
  };

  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        right: 0,
        '&.MuiBox-root': {
          mt: 0,
        },
        textAlign: 'right',
      }}
    >
      {isLoading ? (
        <Box sx={{ display: 'flex' }}>
          <CircularProgress size={20} thickness={4} />
        </Box>
      ) : (
        <>
          <List
            sx={{ width: '100%', maxWidth: 460, minWidth: 320, maxHeight: 200, overflow: 'auto' }}
          >
            {attachments.map((file: any, idx) => {
              const labelId = `checkbox-list-label-${idx}`;
              const actionDisabled = file.status !== FileStatus[FileStatus.SAVED];
              return (
                <ListItem key={`${idx}-${file.fileName}`} disablePadding divider>
                  <ListItemButton dense disabled={disabled} sx={{ paddingRight: 8 }}>
                    <ListItemIcon sx={{ minWidth: 32 }}>
                      <Tooltip
                        title={
                          file.contentType === 'pdf'
                            ? 'Select a primary file'
                            : 'Non-PDF cannot be selected as primary file'
                        }
                      >
                        <span>
                          <Checkbox
                            edge="start"
                            checked={file.category === AttachmentCategory.PRIMARY}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ 'aria-labelledby': labelId }}
                            disabled={file.contentType !== 'pdf'}
                            onClick={(e) =>
                              id && handlePrimaryAttachmentToogle(id, file.fileName, e)
                            }
                          />
                        </span>
                      </Tooltip>
                    </ListItemIcon>
                    <ListItemText
                      id={labelId}
                      primary={file.fileName}
                      secondary={file.status}
                      sx={{
                        marginTop: 0,
                        marginBottom: 0,
                        paddingRight: 3,
                        wordWrap: 'break-word',
                      }}
                      secondaryTypographyProps={{ fontSize: '0.75rem' }}
                    />
                    <ListItemSecondaryAction>
                      <Tooltip title="Download">
                        <span>
                          <IconButton
                            aria-label="download"
                            onClick={() => handleFileDownload(file.fileName)}
                            disabled={actionDisabled}
                          >
                            <DownloadIcon />
                          </IconButton>
                        </span>
                      </Tooltip>
                      <Tooltip title="Delete">
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => id && handleFileRemove(id, file.requestId, file.fileName)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </ListItemSecondaryAction>
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>

          <Tooltip
            title={
              disabled
                ? !id
                  ? 'Create a form to upload a file'
                  : 'Switch edit on to upload a file'
                : ''
            }
          >
            <span>
              <Button component="label" variant="outlined" disabled={disabled}>
                <FileUploadIcon />
                <input
                  ref={inputRef}
                  type="file"
                  hidden
                  onChange={(e) => handleFileUpload(e)}
                  multiple={true}
                  onClick={() => {
                    if (inputRef.current) inputRef.current.value = '';
                  }}
                />
              </Button>
            </span>
          </Tooltip>
        </>
      )}
    </Box>
  );
}

export { FileUpload };
